<template>
  <div class="container">
    <el-descriptions title="用户信息">
      <el-descriptions-item label="银行卡开户姓名">{{ info.bankRealName || '暂无' }}</el-descriptions-item>
      <el-descriptions-item label="银行预留手机号码">{{ info.bankReservePhone || '暂无' }}</el-descriptions-item>
      <el-descriptions-item label="银行卡名称">{{ info.bankName || '暂无' }}</el-descriptions-item>
      <el-descriptions-item label="银行支行名称">{{ info.bankBranchName || '暂无' }}</el-descriptions-item>
      <el-descriptions-item label="银行卡号">{{ info.bankCard || '暂无' }}</el-descriptions-item>
      <el-descriptions-item label="总余额">{{ info.totalMoney || '暂无' }}</el-descriptions-item>
      <el-descriptions-item label="可用余额">{{ info.usableMoney || '暂无' }}</el-descriptions-item>
      <el-descriptions-item label="冻结余额">{{ info.frozenMoney || '暂无' }}</el-descriptions-item>
    </el-descriptions>
    <h3>下级用户信息</h3>
    <el-table :data="subordinateUsersList" border stripe style="width: 100%">
      <el-table-column align="center" label="id" prop="id" width="150"></el-table-column>
      <el-table-column align="center" label="微信昵称	" prop="nickName"></el-table-column>
      <el-table-column align="center" label="用户头像">
        <template slot-scope="scope">
          <el-image style="width: 50px; height: 50px" :src="scope.row.imgUrl" fit="fill"></el-image>
        </template>
      </el-table-column>
      <el-table-column align="center" label="用户类型">
        <template slot-scope="scope">
          <el-tag :type="statusColor[scope.row.suType]">{{ status[scope.row.suType] }}</el-tag>
        </template>
      </el-table-column>
      <el-table-column align="center" label="地址">
        <template slot-scope="scope">
          <span>{{ scope.row.province }}</span>
          <span>{{ scope.row.city }}</span>
          <span>{{ scope.row.district }}</span>
        </template>
      </el-table-column>
      <el-table-column align="center" label="用户状态">
        <template slot-scope="scope">
          <el-tag :type="scope.row.status == 0 ? 'success' : 'danger'" disable-transitions>
            {{ scope.row.status == 0 ? '启用' : '停用' }}
          </el-tag>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import {suTypeObj, suTypeObjColor} from '../../views/user/types'

export default {
  props: {
    info: {
      type: Object,
      default: {},
    },
    subordinateUsersList: {
      type: Array,
      default: []
    }
  },
  data() {
    return {
      status: suTypeObj,
      statusColor: suTypeObjColor
    }
  }
};
</script>

<style lang="less" scoped>
.list {
  height: 50px;
  font-size: 16px;
  border: 1px solid #eee;
  border-bottom: none;

  > div {
    flex: 1;
    height: 100%;
  }

  > div:nth-child(odd) {
    border-right: 1px solid #eee;
  }

  .label {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 180px;
    height: 100%;
  }

  .content {
    display: flex;
    align-items: center;
    flex: 1;
    height: 100%;
    font-weight: bold;
  }
}

.list:nth-child(4) {
  border-bottom: 1px solid #eee;
}
</style>