<template>
  <div class="container">
    <el-card class="box-card">

      <div class="share-content layout">
        <el-input v-model="query.id" placeholder="用户id"></el-input>

        <el-input v-model="query.suId" placeholder="上级id"></el-input>

        <el-input v-model="query.phone" placeholder="手机号码"></el-input>

        <el-select v-model="query.suType" placeholder="用户类型">
          <el-option v-for="item in queryStatus" :key="item.value" :label="item.label" :value="item.value"></el-option>
        </el-select>
        <el-button type="success" @click="getData">搜索</el-button>
        <el-button type="warning" @click="handlerReset">重置</el-button>
      </div>

      <el-table :data="tableData" border stripe style="width: 100%" v-loading="loading">
        <el-table-column align="center" label="id" prop="id" width="100"></el-table-column>
        <el-table-column align="center" label="用户昵称" prop="nickName"></el-table-column>
        <el-table-column align="center" label="手机号码" prop="phone" width="130"></el-table-column>
        <el-table-column align="center" label="上级ID" prop="suId" width="100"></el-table-column>
        <el-table-column align="center" label="上级微信昵称" prop="suNickName"></el-table-column>
        <el-table-column align="center" label="用户头像" width="120">
          <template slot-scope="scope">
            <el-image style="width: 50px; height: 50px" :src="scope.row.imgUrl" fit="fill"></el-image>
          </template>
        </el-table-column>

        <el-table-column align="center" label="地址" prop="phone">
          <template slot-scope="scope">
            <span>{{ scope.row.province }}</span>
            <span>{{ scope.row.city }}</span>
            <span>{{ scope.row.district }}</span>
          </template>
        </el-table-column>

        <el-table-column align="center" width="150" label="用户类型">
          <template slot-scope="scope">
            <el-tag :type="statusColor[scope.row.suType]" disable-transitions>{{ status[scope.row.suType] }}</el-tag>
          </template>
        </el-table-column>

        <el-table-column align="center" label="会员开始-结束时间" width="240">
          <template slot-scope="scope" v-if="scope.row.vipStartTime">
            <span>{{ $moment(scope.row.vipStartTime).format("YYYY-MM-DD") }}</span>
            <span style="margin:0 10px;">至</span>
            <span>{{ $moment(scope.row.vipEndTime).format("YYYY-MM-DD") }}</span>
          </template>
        </el-table-column>


        <el-table-column align="center" label="用户状态">
          <template slot-scope="scope">
            <el-tag :type="scope.row.status == 0 ? 'success' : 'danger'" disable-transitions>
              {{ scope.row.status == 0 ? '启用' : '停用' }}
            </el-tag>
          </template>
        </el-table-column>
        <el-table-column align="center" label="操作" width="380">
          <template slot-scope="scope">
            <el-button type="success" @click="handlerDetail(scope.row.id)" icon="el-icon-delete" size="small ">用户详情
            </el-button>
            <el-button type="success" @click="bindUser(scope.row.id)" icon="el-icon-crop" size="small ">绑定上级用户
            </el-button>
            <el-button type="primary" @click="handlerEdit(scope.row)" icon="el-icon-edit" size="small ">编辑</el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="query.current"
                     :page-sizes="[5, 10, 20, 30]" :page-size="query.size"
                     layout="total, sizes, prev, pager, next, jumper" :total="total">
      </el-pagination>
    </el-card>
    <!-- 编辑弹框 -->
    <el-dialog title="修改用户" :visible.sync="teacherShow" width="46%" @close="cancel">
      <el-form :model="form" ref="form" label-width="150px" class="demo-ruleForm" :rules="rules">
        <el-form-item label="微信昵称:" prop="nickName">
          <el-input @focus="clearValidate('nickName')" placeholder="请输入微信昵称" v-model="form.nickName"
                    autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="上级用户ID:">
          <el-input placeholder="请输入上级用户ID" v-model="form.suId" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="用户头像:" prop="imgUrl">
          <el-upload class="avatar-uploader" :show-file-list="false" action="/api/file/upload"
                     :on-success="successImages">
            <img v-if="form.imgUrl" :src="form.imgUrl" class="avatar">
            <i v-else class="el-icon-plus avatar-uploader-icon layout_sa"></i>
          </el-upload>
        </el-form-item>
        <el-form-item label="地区:" prop="city">
          <el-cascader @change="changeAddress" placeholder="请选择城市" :props="{ value: 'label' }" :options="options"
                       v-model="form.address">
          </el-cascader>
        </el-form-item>
        <el-form-item label="状态:" style="margin-top:10px;">
          <el-switch v-model="statusOpen" active-text="开启" inactive-text="关闭"></el-switch>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button size="mini" @click="cancel">取 消</el-button>
        <el-button size="mini" type="primary" @click="submit">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 用户信息 -->
    <el-dialog title="用户详细信息" :visible.sync="userInfoShow" width="56%" @close="userInfoShow = false">
      <user-tabel :info="userInfo" :subordinateUsersList="subordinateUsersList"></user-tabel>
    </el-dialog>

    <!-- 绑定上级用户 -->
    <el-dialog class="bind-sup-user" title="绑定上级用户" :visible.sync="bindSupShow" width="45%" @close="bindDialog">
      <div class="search-box">
        <el-input placeholder="请输入上级用户的手机号码" size="small" v-model="supMobile" clearable></el-input>
        <el-button type="primary" size="small" icon="el-icon-search" @click="getSuUserInfoByMobile">查询</el-button>
        <span v-if="this.bindUserInfo.suId" style="color: #F56C6C;margin-left: 30px">* 当前用户已绑定了上级用户，请谨慎操作！</span>
      </div>
      <div class="user-info">
        <div>
          <el-descriptions title="当前用户" :column="1">
            <el-descriptions-item label="用户头像">
              <el-image style="width: 80px; height: 80px" :src="bindUserInfo.imgUrl" fit="fill"></el-image>
            </el-descriptions-item>
            <el-descriptions-item label="用户ID">{{ bindUserInfo.id }}</el-descriptions-item>
            <el-descriptions-item label="上级ID">{{ bindUserInfo.suId }}</el-descriptions-item>
            <el-descriptions-item label="用户昵称">{{ bindUserInfo.nickName }}</el-descriptions-item>
            <el-descriptions-item label="手机号码"><span style="color: #409EFF; font-weight: bold">{{
                bindUserInfo.phone
              }}</span></el-descriptions-item>
            <el-descriptions-item label="用户类型">
              <el-tag :type="statusColor[bindUserInfo.suType]" disable-transitions>{{
                  status[bindUserInfo.suType]
                }}
              </el-tag>
            </el-descriptions-item>
          </el-descriptions>
        </div>
        <div>
          <el-descriptions title="上级用户" :column="1">
            <el-descriptions-item label="用户头像">
              <el-image style="width: 80px; height: 80px" :src="supUserInfo.imgUrl" fit="fill"></el-image>
            </el-descriptions-item>
            <el-descriptions-item label="用户ID">{{ supUserInfo.id || "-" }}</el-descriptions-item>
            <el-descriptions-item label="上级ID">{{ supUserInfo.suId || "-" }}</el-descriptions-item>
            <el-descriptions-item label="用户昵称">{{ supUserInfo.nickName || "-" }}</el-descriptions-item>
            <el-descriptions-item label="手机号码">
              <span style="color: #409EFF; font-weight: bold" v-if="supUserInfo.phone">{{ supUserInfo.phone }}</span>
              <span v-else>-</span>
            </el-descriptions-item>
            <el-descriptions-item label="用户类型">
              <el-tag v-if="!isNaN(supUserInfo.suType)" :type="statusColor[supUserInfo.suType]" disable-transitions>
                {{ status[supUserInfo.suType] }}
              </el-tag>
              <span v-else>-</span>
            </el-descriptions-item>
          </el-descriptions>
        </div>
      </div>
      <div class="bind-btn">
        <el-button type="primary" @click="bindCommit">立即绑定</el-button>
      </div>
    </el-dialog>

  </div>
</template>

<script>
import {
  suUserList,
  getSuUserId,
  setUser,
  getSuUserInfoId,
  subordinateUsers,
  userInfoByMobile,
  bindSup
} from "../../apis/user";
import {terminal} from "@/utils/rules";
import {regionData} from "element-china-area-data";
import userTabel from "../../components/user/userTabel.vue";
import UserList from "../system/UserList.vue";
import {suTypeObj, suTypeObjColor, queryStatus} from './types'

export default {
  components: {userTabel, UserList},
  data() {
    return {
      loading: false,
      tableData: [],
      total: null,
      query: {
        current: 1,
        size: 10,
        id: "",
        suId: "",
        phone: "",
        suType: ""
      },
      teacherShow: false,
      bindSupShow: false,
      supMobile: "",
      rules: terminal.rules,
      form: {
        id: "", //	id
        nickName: "", //	微信昵称
        imgUrl: "", //	用户头像
        province: "", //	省
        city: "", //	市
        district: "", //	区
        status: "", //	用户状态(0=正常 1=禁用)
        address: "",
        suId: '', // 上级id
      },
      options: regionData,
      statusOpen: true,
      userInfo: {},
      bindUserInfo: {},
      supUserInfo: {},
      userInfoShow: false,
      subordinateUsersList: [],
      status: suTypeObj,
      queryStatus: queryStatus,
      statusColor: suTypeObjColor
    };
  },
  created() {
    this.getData();
  },
  methods: {
    async handlerDetail(id) {
      const res = await getSuUserInfoId({id});
      const ret = await subordinateUsers({id})
      this.subordinateUsersList = ret.data
      this.userInfo = res.data;
      this.userInfoShow = true;
    },
    submit() {
      this.$refs.form.validate(async (valid) => {
        if (!valid) return;
        delete this.form.address;
        this.form.status = this.statusOpen ? 0 : 1;
        const res = await setUser(this.form);
        if (res.code == 200) {
          this.$message({message: "编辑成功", type: "success"});
          this.teacherShow = false;
          this.getData();
          this.clearCen();
        }
      });
    },
    async handlerEdit(item) {
      const res = await getSuUserId({id: item.id});
      const {province, city, district, status} = res.data;
      this.form = {...item};
      this.form.address = [province, city, district];
      this.statusOpen = status == 0 ? true : false;
      this.teacherShow = true;
    },
    changeAddress(val) {
      if (!val) return;
      this.form.province = val[0];
      this.form.city = val[1];
      this.form.district = val[2];
    },

    // 上传图片成功的钩子函数
    successImages(response, file, fileList) {
      this.form.imgUrl = response.data;
      this.$refs.form.clearValidate("imgUrl");
    },

    // 获取焦点对表单重新校验
    clearValidate(prop) {
      this.$refs.form.clearValidate(prop);
    },
    // 关闭弹框
    cancel() {
      this.teacherShow = false;
      this.date = "";
      this.clearCen();
    },

    // 初始化状态
    clearCen() {
      this.$refs.form.resetFields(); // 重置表单
      Object.keys(this.form).map((key) => (this.form[key] = ""));
      this.statusOpen = true;
    },

    handleSizeChange(size) {
      this.query.size = size;
      this.getData();
    },
    handleCurrentChange(page) {
      this.query.current = page;
      this.getData();
    },

    async getData() {
      this.loading = true;
      const res = await suUserList(this.query);
      const {records, total, current, size} = res.data;
      this.tableData = records;
      this.total = total;
      this.query.current = current;
      this.query.size = size;
      setTimeout(() => {
        this.loading = false;
      }, 50);
    },
    handlerReset() {
      this.query = {
        ...this.query,
        id: "",
        suId: "",
        phone: "",
        suType: ""
      }
      this.getData()
    },

    async bindUser(id) {
      const res = await getSuUserId({id});
      console.log(res);
      this.bindUserInfo = res.data;
      this.bindSupShow = true;
    },

    async getSuUserInfoByMobile() {
      const res = await userInfoByMobile({mobile: this.supMobile});
      console.log(res);
      this.supUserInfo = res.data;
      /*this.bindUserInfo = res.data;
      const ret = await subordinateUsers({id: this.bindUserInfo.id});
      this.bindSubordinateUsersList = ret.data;*/
    },

    async bindCommit() {
      const res = await bindSup({currentId: this.bindUserInfo.id, superiorId: this.supUserInfo.id})
      console.log(res)
      if (res.code == 200) {
        this.$message({message: "绑定成功", type: "success"});
        this.bindSupShow = false;
      }
    },
    bindDialog() {
      this.bindSupShow = false;
      this.supUserInfo = {};
      this.supMobile = "";
    }

  },
};
</script>

<style lang="less" scoped>
/deep/ .el-dialog__body {
  padding-right: 50px !important;
}

.form-list {
  display: flex;
}

/deep/ .el-cascader {
  width: 100% !important;
}

.el-form-item {
  width: 60%;
}

.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}

.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 100px;
  height: 100px;
  border: 1px dashed #999;
}

.avatar {
  width: 100px;
  height: 100px;
  display: block;
}

.share-content {
  justify-content: flex-start;

  .el-input {
    width: 160px;
    margin-right: 10px;
  }

  .el-select {
    width: 180px;
    margin-right: 10px;
  }
}

.bind-sup-user {
  display: flex;
  padding: 60px !important;

  .search-box {
    display: flex;
    margin: 30px 0;

    .el-input {
      width: 220px;
      margin-right: 20px;
    }
  }

  .user-info {
    display: flex;
  }

  .bind-btn {
    margin: 30px 0;
    display: flex;

    .el-button {
      width: 120px;
      margin: 0 auto;
    }
  }
}


</style>
